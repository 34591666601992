var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-form"},[_c('div',{staticClass:"edit-form__header"},[_c('span',{staticClass:"titlex",style:({
        fontSize: ("" + (_vm.$vuetify.breakpoint.mdAndDown ? '20px' : '32px')),
      })},[_vm._v("Edit Form")]),_c('div',{staticClass:"edit-form__header--actions"},[(_vm.canPreview && !_vm.isSubmitting)?_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"height":"54px","background":"#d9dee1","border-radius":"4px"},style:({
          width: ("" + (_vm.$vuetify.breakpoint.mdAndDown ? '130px' : '215px')),
        }),attrs:{"dark":"","elevation":"0"},on:{"click":function($event){_vm.showPreview = !_vm.showPreview}}},[_c('img',{attrs:{"src":require('@/assets/pbot_icons/view.svg')}}),_c('span',{staticStyle:{"padding-left":"8px","font-family":"Inter","font-style":"normal","font-weight":"500","font-size":"14px","line-height":"17px","text-align":"center","letter-spacing":"0.636364px","color":"var(--v-primary-base)"},style:({
            fontSize: ("" + (_vm.$vuetify.breakpoint.mdAndDown ? '12px' : '14px')),
          })},[_vm._v(" "+_vm._s(!_vm.showPreview ? "Preview" : "Edit")+" ")])]):_vm._e(),_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"height":"54px","background":"var(--v-primary-base)","box-shadow":"0px 3px 5px -1px rgb(0 0 0 / 3%),","border-radius":"4px"},style:({
          width: ("" + (_vm.$vuetify.breakpoint.mdAndDown ? '130px' : '215px')),
          opacity: ("" + (!_vm.canPreview ? '0.8' : '1')),
          boxShadow: ("" + (_vm.canPreview ? '0px 12px 22px rgba(0, 0, 0, 0.24)' : 'none')),
        }),attrs:{"loading":_vm.isSubmitting,"dark":""},on:{"click":_vm.submitForm}},[_c('img',{attrs:{"src":require('@/assets/pbot_icons/add-form.svg')}}),_c('span',{staticStyle:{"padding-left":"8px","font-family":"Inter","font-style":"normal","font-weight":"500","font-size":"14px","line-height":"17px","text-align":"center","letter-spacing":"0.636364px","color":"#ffffff"},style:({
            fontSize: ("" + (_vm.$vuetify.breakpoint.mdAndDown ? '12px' : '14px')),
          })},[_vm._v(" Save ")])])],1)]),(_vm.formDetails)?_c('div',{staticClass:"edit-form__canvas-area"},[_c('div',{staticClass:"form-actions"},[_c('v-switch',{staticClass:"m-0 p-0",attrs:{"color":"#19283D","hide-details":"auto","label":"This form is Private"},model:{value:(_vm.formDetails.is_private),callback:function ($$v) {_vm.$set(_vm.formDetails, "is_private", $$v)},expression:"formDetails.is_private"}}),_c('button',{on:{"click":_vm.openFormSettings}},[_c('v-icon',[_vm._v("mdi-cog-outline")]),_vm._v(" form settings ")],1)],1),_c('div',{staticClass:"form-canvas"},[_c('hyphen-form-builder',{attrs:{"id":"form-builder","show-preview":_vm.showPreview,"form-id":_vm.formDetails.hypn_id,"hyphen-token":_vm.token,"hyphen-base-url":_vm.getBaseUrl()},domProps:{"details":{
          name: _vm.formDetails.form_title,
          description: _vm.formDetails.form_description,
        },"preSchema":_vm.currentFormSchema},on:{"schema":_vm.getSchema,"canPreview":_vm.setPreviewState}})],1)]):_vm._e(),_c('feed-back-modal',{ref:"feedBackModal",attrs:{"isEdit":""}}),_c('create-form-modal',{ref:"formsettings",attrs:{"formValue":_vm.fullForm,"isEdit":""},on:{"updated":_vm.fetchFormsById}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }